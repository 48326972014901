(function() {
    "use strict";
    var sliderImagesDataTable,
        facultyDataTable,
        feeStructureDataTable,
        documentDataTable,
        tcDataTable,
        galleryImagesDataTable;

    var eminent = {
        generalElements: function() {
            /**
             * Setup CSRF token in ajax request
             */
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            /**
             * This is for delete buttons that are loaded via AJAX in datatables, they will not work right
             * without this block of code
             */
            $(document).ajaxComplete(function(){
                eminent.addDeleteForms();
                $('.select2').select2({});
                // Update card filter button text
                if ($('#filter-cards').length) {
                    $('#filter-cards').html('Search').removeClass('disabled');
                }
            });

            /**
             * Generic confirm form delete using Sweet Alert
             */
            $('body').on('submit', 'form[name=delete_item]', function(e){
                e.preventDefault();
                var form = this;
                var link = $('a[data-method="delete"]');
                var cancel = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel";
                var confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete";
                var title = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure?";
                var text = (link.attr('data-trans-text')) ? link.attr('data-trans-text') : "You won't be able to revert this!";

                Swal.fire({
                    title: title,
                    text: text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: cancel,
                    confirmButtonText: confirm,
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        form.submit();
                    }
                })
            });

            // Initialize select2
            $('.select2').select2({

            });

            // Set default config for jquery validate
            $.validator.setDefaults({
                debug: false,
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorElement: 'div',
                errorPlacement: function(error, element) {
                    error.addClass('invalid-feedback');
                    if ( element.prop( "type" ) === "checkbox" ) {
                        error.insertAfter( element.next( "label" ) );
                    }
                    else if(element.hasClass("select2")) {
                        error.insertAfter( element.next("span"));
                    }
                    else {
                        error.insertAfter( element );
                    }
                    //error.insertAfter( element );
                }
            });
        },
        /**
         * Allows you to add data-method="METHOD to links to automatically inject a form
         * with the method on click
         *
         * Example: <a href="{{route('customers.destroy', $customer->id)}}"
         * data-method="delete" name="delete_item">Delete</a>
         *
         * Injects a form with that's fired on click of the link with a DELETE request.
         * Good because you don't have to dirty your HTML with delete forms everywhere.
         */
        addDeleteForms: function() {
            $('[data-method]').append(function () {
                if (! $(this).find('form').length > 0)
                    return "\n" +
                        "<form action='" + $(this).attr('href') + "' method='POST' name='delete_item' style='display:none'>\n" +
                        "   <input type='hidden' name='_method' value='" + $(this).attr('data-method') + "'>\n" +
                        "   <input type='hidden' name='_token' value='" + $('meta[name="csrf-token"]').attr('content') + "'>\n" +
                        "</form>\n";
                else
                    return "";
            })
                .removeAttr('href')
                .attr('style', 'cursor:pointer;')
                .attr('onclick', '$(this).find("form").submit();');
        },
        // Prepare toast notification
        displayToastNotification: function(type, heading, message) {
            $.toast({
                heading: heading,
                text: message,
                position: 'top-right',
                showHideTransition: 'slide',
                icon: type
            })
        },
        // Disable button
        disableButton: function (form) {
            $(form)
                .find('button[type="submit"]')
                .addClass('disabled')
                .attr('disable', true)
                .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...');
        },

        // Enable button
        enableButton: function (form, btnHtml) {
            $(form)
                .find('button[type="submit"]')
                .removeClass('disabled')
                .attr('disable', false)
                .html(btnHtml);
        },
        // Initialize users dataTable
        sliderImagesDataTable: function () {
            if($('#slider-images-table').length) {
                sliderImagesDataTable = $('#slider-images-table').DataTable({
                    pageLength: 10,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: slider_images_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        //{data: 'bulk_action', name: 'bulk_action', searchable: false, sortable: false},
                        {data: 'image', name: 'image'},
                        {data: 'title', name: 'title', class: 'text-center'},
                        {data: 'description', name: 'description'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[3, "desc"]],
                    searchDelay: 500
                });

                // Bulk delete users
                $('#bulk-delete-btn').on('click', function () {
                    var ids = [];
                    $('input[type="checkbox"][name="ids[]"]:checked').each(function () {
                        ids.push($(this).val());
                    });
                    Swal.fire({
                        title: 'Are you sure?',
                        text: 'You won\'t be able to revert this!',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancel',
                        confirmButtonText: 'Yes, delete',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var buttonHtml = $(this).html();

                            // Enable loading on delete button
                            $(this).attr('disabled', true).html('<span class="spinner-border spinner-border-sm" role="status"></span> Deleting...');
                            axios.post(user_bulk_delete_route, {ids: ids})
                                .then(function (response) {
                                    $('#bulk-delete-btn').attr('disabled', false).html(buttonHtml);
                                    $('#bulk-delete-row').addClass('d-none');
                                    $('#checked-all').prop('checked', false);
                                    $('#checked-all').prop('indeterminate', false);
                                    sliderImagesDataTable.ajax.reload();
                                    if (response.data.status == 'success') {
                                        eminent.displayToastNotification('success', 'Success', 'Selected users deleted.');
                                    }
                                    else {
                                        eminent.displayToastNotification('error', 'Error', 'Unable to delete, please try again.');
                                    }
                                })
                                .catch(function (error) {

                                })
                        }
                    })
                });
            }
        },

        // Upload slider image
        uploadSliderImage: function () {
            $('#slider-image-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var formAction  = $(form).attr('action');
                    var btnHtml     = $(form).find('button[type="submit"]').html();
                    // Hide error message
                    //$('#profile-validation-error').addClass('d-none');
                    // Disable button
                    eminent.disableButton(form);
                    // Prepare form object
                    let formData = new FormData(form);

                    // Send and save the message
                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                eminent.displayToastNotification('success', 'Success', response.data.message);
                                $(form).trigger("reset");

                                // Reload the image dataTable
                                sliderImagesDataTable.ajax.reload();

                                // Hide the modal
                                $('#uploadImage').modal('hide');
                                $('#preview-img-row').addClass('d-none'); // hide the preview image
                                $('#profile-image-display').attr('src', ""); // Empty the image src
                            }
                        })
                        .catch(function (error) {
                            let errorMessage = eminent.formatErrorMessage(error.response.data);
                            $('#profile-validation-error').html(errorMessage).removeClass('d-none');
                        })
                        .finally(function () {
                            //
                            eminent.enableButton(form, btnHtml);
                            $(form).trigger("reset");
                        });
                    return false;
                }
            });

            // Display image preview
            $('#slider-image').on('change', function () {
                const file = this.files[0];
                if(file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('#profile-image-display').attr('src', e.target.result);
                        $('#preview-img-row').removeClass('d-none');
                    }
                    reader.readAsDataURL(file);
                }
            });
        },

        // Delete slider image
        deleteSliderImage: function () {
            $('body').on('click', '.delete-slider-image', function (e) {
                e.preventDefault();
                let action = $(this).attr('href');

                var link    = $(this);
                var cancel  = (link.attr('data-trans-button-cancel')) ? link.attr('data-trans-button-cancel') : "Cancel";
                var confirm = (link.attr('data-trans-button-confirm')) ? link.attr('data-trans-button-confirm') : "Yes, delete";
                var title   = (link.attr('data-trans-title')) ? link.attr('data-trans-title') : "Are you sure?";
                var text    = (link.attr('data-trans-text')) ? link.attr('data-trans-text') : "You won't be able to revert this!";

                Swal.fire({
                    title: title,
                    text: text,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: cancel,
                    confirmButtonText: confirm,
                    reverseButtons: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Send and save the message
                        axios.delete(action)
                            .then(function (response) {
                                if(response.data.status == 'success') {
                                    eminent.displayToastNotification('success', 'Success', response.data.message);
                                    // Reload the image dataTable
                                    sliderImagesDataTable.ajax.reload();
                                }
                                else {
                                    eminent.displayToastNotification('error', 'Error', response.data.message);
                                }
                            })
                            .catch(function (error) {
                                eminent.displayToastNotification('error', 'Error', 'There was a problem deleting this image. Please try again.');
                            })
                            .finally(function () {

                            });
                    }
                })
            });
        },

        // Display edit slider image form
        editSliderImage: function () {
            // Get the edit form
            $('body').on('click', '.edit-slider-image', function (e) {
                e.preventDefault();
                let editUrl = $(this).attr('href');
                axios.get(editUrl)
                    .then(function (response) {
                        if(response.data.status == 'success') {
                            $('#edit-slider-image-container').html(response.data.form_html);
                            $('#editSliderImageModal').modal('show');
                            eminent.updateSliderImage();
                        }
                        else {
                            $('#edit-slider-image-container').html('');
                        }
                    })
            });

            // Preview for selected image
            $('body').on('change', '#update-slider-image', function () {
                const file = this.files[0];
                if(file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('#update-slider-image-preview').attr('src', e.target.result);
                    }
                    reader.readAsDataURL(file);
                }
            });
        },

        // Update slider image
        updateSliderImage: function () {
            // Update slider image
            $('#update-slider-image-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var formAction  = $(form).attr('action');
                    var btnHtml     = $(form).find('button[type="submit"]').html();
                    // Hide error message
                    //$('#profile-validation-error').addClass('d-none');
                    // Disable button
                    eminent.disableButton(form);
                    // Prepare form object
                    let formData = new FormData(form);

                    // Send and save the message
                    axios.post(formAction, formData)
                        .then(function (response) {
                            if(response.data.status == 'success') {
                                eminent.displayToastNotification('success', 'Success', response.data.message);
                                $(form).trigger("reset");

                                // Reload the image dataTable
                                sliderImagesDataTable.ajax.reload();

                                // Hide the modal
                                $('#editSliderImageModal').modal('hide');
                                $('#update-slider-image-preview').attr('src', ""); // Empty the image src
                            }
                        })
                        .catch(function (error) {
                            eminent.displayToastNotification('error', 'Error', 'There was a problem updating this image. Please try again.');
                        })
                        .finally(function () {
                            //
                            eminent.enableButton(form, btnHtml);
                            $(form).trigger("reset");
                        });
                    return false;
                }
            });
        },

        // Initialize faculties dataTable
        facultyDataTable: function () {
            if($('#faculties-table').length) {
                facultyDataTable = $('#faculties-table').DataTable({
                    pageLength: 10,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: faculties_list_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        //{data: 'bulk_action', name: 'bulk_action', searchable: false, sortable: false},
                        {data: 'name', name: 'name', class: 'text-center'},
                        {data: 'profile_pic', name: 'profile_pic', class: 'text-center'},
                        {data: 'position', name: 'position', class: 'text-center'},
                        {data: 'thought_title', name: 'thought_title', class: 'text-center'},
                        {data: 'thought_description', name: 'thought_description'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[5, "desc"]],
                    searchDelay: 500
                });
            }

            // Preview faculty profile pic
            $('body').on('change', '#chose-faculty-profile-pic', function () {
                const file = this.files[0];
                if(file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('#preview-faculty-profile-pic').attr('src', e.target.result).removeClass('d-none');
                    }
                    reader.readAsDataURL(file);
                }
            });
        },
        validateForm: function () {
            $('.validate-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    var btnHtml     = $(form).find('button[type="submit"]').html();
                    // Disable button
                    eminent.disableButton(form);
                    form.submit();
                }
            });
        },
        // Initialize Fee structure dataTable
        feeStructureDataTable: function () {
            if($('#fees-table').length) {
                feeStructureDataTable = $('#fees-table').DataTable({
                    pageLength: 50,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: fees_list_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        //{data: 'bulk_action', name: 'bulk_action', searchable: false, sortable: false},
                        {data: 'class', name: 'class'},
                        {data: 'subject', name: 'subject'},
                        {data: 'tuition_fee', name: 'tuition_fee', class: 'text-center'},
                        {data: 'tuition_fee_old_student', name: 'tuition_fee_old_student', class: 'text-center'},
                        {data: 'bus_fee', name: 'bus_fee', class: 'text-center'},
                        {data: 'total_fee', name: 'total_fee', searchable: false, sortable: false, class: 'text-center'},
                        {data: 'total_fee_old_student', name: 'total_fee_old_student', searchable: false, sortable: false, class: 'text-center'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[7, "desc"]],
                    searchDelay: 500
                });
            }

            // Show/Hide custom fee
            $('#isCustomFee').on('change', function () {
                if($(this).is(':checked')) {
                    $('.school-fee-container').addClass('d-none');
                    $('.miscellaneous-fee-container').removeClass('d-none');

                    $('.school-fee-input').removeClass('required');
                    $('.miscellaneous-fee-input').addClass('required');
                }
                else {
                    $('.school-fee-container').removeClass('d-none');
                    $('.miscellaneous-fee-container').addClass('d-none');

                    $('.school-fee-input').addClass('required');
                    $('.miscellaneous-fee-input').removeClass('required');
                }
            });
        },
        // Init document dataTable
        documentDataTable: function () {
            if($('#documents-table').length) {
                documentDataTable = $('#documents-table').DataTable({
                    pageLength: 50,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: documents_list_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        {data: 'title', name: 'title'},
                        {data: 'preview', name: 'preview', searchable: false, sortable: false, class: 'text-center'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[2, "desc"]],
                    searchDelay: 500
                });
            }
        },
        // Init TC dataTable
        tcDataTable: function () {
            if($('#tc-table').length) {
                tcDataTable = $('#tc-table').DataTable({
                    pageLength: 50,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: tc_list_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        {data: 'name', name: 'name'},
                        {data: 'scholar_number', name: 'scholar_number',  class: 'text-center'},
                        {data: 'roll_number', name: 'roll_number',  class: 'text-center'},
                        {data: 'class', name: 'class',  class: 'text-center'},
                        {data: 'file_name', name: 'file_name',  class: 'text-center'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[5, "desc"]],
                    searchDelay: 500
                });
            }

            // Preview TC when select it
            $('body').on('change', '#chose-tc', function () {
                const file = this.files[0];
                if(file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('#preview-tc').attr('src', e.target.result).removeClass('d-none');
                    }
                    reader.readAsDataURL(file);
                }
            });
        },

        // Init gallery images dataTable galleryImagesDataTable
        galleryImagesDataTable: function () {
            if($('#gallery-table').length) {
                galleryImagesDataTable = $('#gallery-table').DataTable({
                    pageLength: 50,
                    stateSave: false,
                    processing: true,
                    serverSide: true,
                    ajax: {
                        url: gallery_list_route,
                        type: 'post',
                        data: function(d) {}
                    },
                    columns: [
                        {data: 'image_title', name: 'image_title'},
                        {data: 'category', name: 'category'},
                        {data: 'file_name', name: 'file_name', class: 'text-center'},
                        {data: 'created_at', name: 'created_at', class: 'text-center'},
                        {data: 'actions', name: 'actions', searchable: false, sortable: false, class: 'text-center'}
                    ],
                    language: {
                        emptyTable: 'No data available.',
                        search: '',
                        searchPlaceholder: 'Search...'
                    },
                    order: [[3, "desc"]],
                    searchDelay: 500
                });
            }

            // Preview TC when select it
            $('body').on('change', '#chose-gallery-img', function () {
                const file = this.files[0];
                if(file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $('#preview-gallery-img').attr('src', e.target.result).removeClass('d-none');
                    }
                    reader.readAsDataURL(file);
                }
            });
        },
        init: function () {
            eminent.generalElements();
            eminent.addDeleteForms();
            eminent.sliderImagesDataTable();
            eminent.uploadSliderImage();
            eminent.deleteSliderImage();
            eminent.editSliderImage();
            eminent.updateSliderImage();
            eminent.facultyDataTable();
            eminent.validateForm();
            eminent.feeStructureDataTable();
            eminent.documentDataTable();
            eminent.tcDataTable();
            eminent.galleryImagesDataTable();
        },
    };
    // Initialize the script
    eminent.init();
})();
